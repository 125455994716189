<template>
  <iframe id="iframe" src="" />
</template>

<script>
import { getLoginAccessToken } from "@/modules/common/api/jump-user-admin";

export default {
  computed: {
    projectId() {
      return this.$store.getters.tenant.id;
    },
    serverTips() {
      return this.$store.getters["header/headerData"].tips;
    },
  },
  watch: {
    '$route.name': {
      handler(val) {
        const routeName = val

        const UserCenterRouteNameMap = this.$store.state.header.iframeRouteData

        let redirectName = UserCenterRouteNameMap[val]
        let redirectParams = this.$enCode({
          project_id: this.projectId,
          id: this.projectId,
          ...(this.$route.params||{})
        })

        console.log(
          redirectName, {
          project_id: this.projectId,
          ...(this.$route.params||{})
        })
        // return
        let url = window.serverConfig.VUE_APP_OTHER_BASEURL["user-admin"]
        // let url = decodeURIComponent(this.$route.query.url)
        // let loading = this.$loading({
        //   text: "正在跳转用户中心..."
        // })
        getLoginAccessToken().then(res => {
          const { data } = res

          if (data.is_super === 0) {
            // this.$confirm('暂无操作权限', '提示', {
            //   showCancelButton: false,
            //   type: 'error',
            //   confirmButtonText: '返回'
            // }).then(() => {
            //   this.$router.back()
            // }).catch(() => {})
            this.$message.error('缺少访问权限')
            return
          }

          url += `?redirect=${redirectName}&t=${new Date().getTime()}`
          if (data.is_super) url += `&superToken=${data.token}`

          // 继续拼接，url 可能为空，可能不为空
          if (redirectParams) url += (url ? '&' : '?') + 'params=' + redirectParams

          console.log(url, '------------------用户中心链接-----------------')
          document.getElementById('iframe')?.setAttribute('src', url)
          // loading.close()
        }).catch(err => {
          // loading.close()
        })
      },
      immediate: true
    }
  },
  created() {
    // 接收用户中心 iframe 的消息
    window.addEventListener('message', e => {
      console.log(e, 'from child page')
      console.log(window.serverConfig.VUE_APP_OTHER_BASEURL['user-admin'], '用户中心地址白名单')
      // 通过origin对消息进行过滤，避免遭到XSS攻击
      if (e.origin === window.serverConfig.VUE_APP_OTHER_BASEURL['user-admin']) {
        // console.log(e.origin) // 子页面所在的域
        // console.log(e.data)  // 子页面发送的消息, hello, parent!

        const {key, val} = e.data
        switch(key) {
          case 'goHome':
            this.$router.replace({name: "Home"}) // 不一定去概览，可能后台账号无该菜单权限的
            break
          default:
        }
      }
    }, false);
  }
}
</script>

<style lang="scss">
  #iframe {
    border: none;
    margin: -$space * 2;
/*    margin-bottom: -$space * 3;*/
    flex: 1;
    min-height: 0;
/*    height: calc(100vh - #{$header-h});*/
    /* 如果需要显示面包屑 */
/*    margin-top: calc(#{-$space * 2} - 50px);
    z-index: 3;*/

    #header, #sidebar {
      display: none;
    }
  }
</style>
